export default class Toggler {
  constructor(element, target, autoClose = false) {
    this.element = element;
    this.target = target;
    this.autoClose = autoClose;
    this.expanded = null;
    this.mounted = false;

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);
    this.handleTarget = this.handleTarget.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
  }

  handleAutoClose(e) {
    if (!this.target.contains(e.target) && !this.element.contains(e.target)) {
      this.hide();
      document.removeEventListener('click', this.handleAutoClose);
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.toggle();
  }

  handleKeyup(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.toggle();
    }
  }

  handleTarget(e) {
    if (e.detail.toggler.element !== this.element) {
      this.expanded = e.detail.toggler.expanded;
      this.element.setAttribute('aria-expanded', this.expanded);

      if (this.autoClose) {
        document.removeEventListener('click', this.handleAutoClose);
      }
    }
  }

  handleTransition() {
    this.target.removeEventListener('transitionend', this.handleTransition);
    this.target.setAttribute('aria-hidden', 'true');
    this.target.classList.remove('is-hiding');
    this.element.dispatchEvent(new CustomEvent("toggler:hide", { detail: { toggler: this } }));
    this.target.dispatchEvent(new CustomEvent("update", { detail: { toggler: this } }));
  }

  toggle() {
    if (this.expanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.element.setAttribute('aria-expanded', 'true');
    this.target.setAttribute('aria-hidden', 'false');
    this.expanded = true;
    this.target.focus();
    this.target.classList.remove('is-hiding');
    this.target.removeEventListener('transitionend', this.handleTransition);
    this.element.dispatchEvent(new CustomEvent("toggler:show", { detail: { toggler: this } }));
    this.target.dispatchEvent(new CustomEvent("update", { detail: { toggler: this } }));

    if (this.autoClose) {
      document.addEventListener('click', this.handleAutoClose);
    }
  }

  hide(ignore = false) {
    if (this.autoClose) {
      document.removeEventListener('click', this.handleAutoClose);
    }

    if (ignore) {
      this.element.setAttribute('aria-expanded', 'false');
      this.target.setAttribute('aria-hidden', 'true');
      this.expanded = false;
    } else {
      this.target.addEventListener('transitionend', this.handleTransition);
      this.target.classList.add('is-hiding');
      this.expanded = false;
      this.element.setAttribute('aria-expanded', 'false');
    }
  }

  mount() {
    this.element.setAttribute('aria-controls', this.target.id);
    this.element.setAttribute('aria-expanded', 'false');
    this.target.setAttribute('aria-hidden', 'true');
    this.target.setAttribute('tabindex', '-1');
    this.expanded = false;
    this.mounted = true;

    if (this.element.tagName !== 'BUTTON') {
      this.element.setAttribute('role', 'button');
      this.element.setAttribute('tabindex', 0);
      this.element.addEventListener('keyup', this.handleKeyup);
    }

    this.element.addEventListener('click', this.handleClick);
    this.target.addEventListener('update', this.handleTarget);
    this.element.dispatchEvent(new CustomEvent("toggler:mounted", { detail: { toggler: this } }));
  }

  unmount() {
    this.element.removeAttribute('aria-controls');
    this.element.removeAttribute('aria-expanded');
    this.target.removeAttribute('aria-hidden');
    this.target.removeAttribute('tabindex');
    this.expanded = null;
    this.mounted = false;

    if (this.element.tagName !== 'BUTTON') {
      this.element.removeAttribute('role');
      this.element.removeAttribute('tabindex');
      this.element.removeEventListener('keyup', this.handleKeyup);
    }

    this.element.removeEventListener('click', this.handleClick);
    this.target.removeEventListener('update', this.handleTarget);
    this.target.removeEventListener('transitionend', this.handleTransition);
    this.element.dispatchEvent(new CustomEvent("toggler:unmounted", { detail: { toggler: this } }));
  }
}
